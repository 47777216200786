<template>
  <div class="min-h-screen bg-cream">
    <SubHeader/>
    <main class="p-6 md:px-0">
      <router-view/>
    </main>
  </div>
</template>

<script>
import SubHeader from "@/components/register/SubHeader";

export default {
  name: "Register",
  components: {SubHeader},
}
</script>

